import executeRequest, { RequestOutput } from 'app/api'
import { APIPaginatable, APIPaginatableResponse, APISortable, APISearchable } from 'app/api/types'

enum APIEndpoint {
  GetProductInformation = 'api/products/getProjectInformations',
  UpdateProductInformation = 'api/products/updateProjectInformations',
}


export type API_ProductInformation = {
  id: number
  passengers?: number | null
  luggage?: number | null
  handLuggage?: number | null
  wifi?: boolean | null
}



export interface GetProductInformationRequestParams extends APIPaginatable, APISortable, APISearchable {
  productId: number
}

export interface GetProductInformationResponse extends APIPaginatableResponse {
  productProjectInformation: API_ProductInformation
}

enum GetProductInformationResponseError {
}

export async function getProductInformation(params: GetProductInformationRequestParams, output?: RequestOutput<GetProductInformationResponse, GetProductInformationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.GetProductInformation,
    params: params,
    output: output,
  })
}




export interface UpdateProductInformationRequestParams extends API_ProductInformation {
  productId: number
}

export interface UpdateProductInformationResponse extends APIPaginatableResponse {
}

enum UpdateProductInformationResponseError {
}

export async function updateProductInformation(params: UpdateProductInformationRequestParams, output?: RequestOutput<UpdateProductInformationResponse, UpdateProductInformationResponseError>) {
  executeRequest({
    endpoint: APIEndpoint.UpdateProductInformation,
    params: params,
    output: output,
  })
}

